<template>
  <rect
    :x="valueX"
    :y="valueY"
    :height="getHeight"
    :width="valueWidth"
    :style="getStyle"
    :hyojiStart="hyojiStart"
    :hyojiEnd="hyojiEnd"
    :rate="rate"
  >
    <title>{{ tooltiptext }}</title>
  </rect>
</template>

<script>
import TooltipInfo from '@/assets/json/GanttChartView.json'
import { GANTT_WORK_KIND, GANTT_RECT_STYLE } from '@/common/const.js'

const START_END_WIDTH = 5

export default {
  props: {
    /**
     * chart情報
     * @type {Object}
     * @requires
     */
    chart: {
      type: Object,
      required: true,
    },
    /**
     * 配送開始時間
     */
    startTime: {
      type: String,
      default: '',
    },
    /**
     * 配送終了時間
     */
    endTime: {
      type: String,
      default: '',
    },
    /**
     * 配送タイプ
     */
    type: {
      type: String,
      default: '',
    },
    /**
     * 表示開始日
     */
    hyojiStart: {
      type: String,
      required: true,
    },
    /**
     * 表示終了日
     */
    hyojiEnd: {
      type: String,
      required: true,
    },
    /**
     *rate
     */
    rate: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    //JSON ファイル表示
    listString() {
      return JSON.stringify(TooltipInfo)
    },
    // 時間をチャートの広さに換算
    valueWidth() {
      let width = 0
      if (
        (this.type == GANTT_WORK_KIND.DepoStart ||
          this.type == GANTT_WORK_KIND.DepoEnd) &&
        this.startTime === this.endTime
      ) {
        width = START_END_WIDTH
      } else {
        let startTime = this.convertDate(this.startTime)
        let endTime = this.convertDate(this.endTime)
        let hyojiEnd = this.convertDate(this.hyojiEnd)
        let result = endTime - hyojiEnd

        let calD
        if (Number(result) < 0) {
          calD = endTime.getTime() - startTime.getTime()
        } else {
          calD = hyojiEnd.getTime() - startTime.getTime()
        }

        // console.log('★end:' + this.endTime );
        //  console.log('★b.getTime():' + b.getTime() );
        //  console.log('★a.getTime():' + a.getTime() );
        // console.log('★width★' + calD / 60000);

        width = Number(calD / 60000)
      }
      width = width * this.rate
      return width
    },

    //時間をx座標に換算
    valueX() {
      let startTime = this.convertDate(this.startTime)
      let hyojiStart = this.convertDate(this.hyojiStart)
      let hyojiEnd = this.convertDate(this.hyojiEnd)

      let result = hyojiEnd - startTime
      let x = 0
      if (result > 0) {
        var hyoji = this.getOnlyDate(hyojiStart)
        // console.log('hyoji:' + hyoji );
        let calD = startTime.getTime() - hyoji.getTime()
        x = Number(calD / 60000)
      }
      if (
        this.type == GANTT_WORK_KIND.DepoStart &&
        this.startTime === this.endTime
      ) {
        x = x - START_END_WIDTH
      }
      x = x * this.rate
      return x
    },

    // 時間をy座標に換算
    valueY() {
      var value = 10
      if (
        this.type == GANTT_WORK_KIND.Move ||
        this.type == GANTT_WORK_KIND.MoveRest ||
        this.type == GANTT_WORK_KIND.MoveEmpty ||
        this.type == GANTT_WORK_KIND.MoveEmptyRest ||
        this.type == GANTT_WORK_KIND.MoveWait ||
        this.type == GANTT_WORK_KIND.Rest
      ) {
        value = 20
      }
      return value
    },

    // 配送タイプに応じてボックスの高さ設定
    getHeight() {
      var value = 70
      if (
        this.type == GANTT_WORK_KIND.Move ||
        this.type == GANTT_WORK_KIND.MoveRest ||
        this.type == GANTT_WORK_KIND.MoveEmpty ||
        this.type == GANTT_WORK_KIND.MoveEmptyRest ||
        this.type == GANTT_WORK_KIND.MoveWait ||
        this.type == GANTT_WORK_KIND.Rest
      ) {
        value = 50
      }
      return value
    },

    // 配送タイプに応じてスタイル設定
    getStyle() {
      switch (this.type) {
        case GANTT_WORK_KIND.DepoStart: //出発
          return GANTT_RECT_STYLE.DepoStart
        case GANTT_WORK_KIND.Depo: //荷積
          return GANTT_RECT_STYLE.Depo
        case GANTT_WORK_KIND.Drop: //荷卸
          return GANTT_RECT_STYLE.Drop
        case GANTT_WORK_KIND.Move: //実車走行
          return GANTT_RECT_STYLE.Move
        case GANTT_WORK_KIND.MoveRest: //実車走行（休憩有）
          return GANTT_RECT_STYLE.MoveRest
        case GANTT_WORK_KIND.MoveEmpty: //空車走行
          return GANTT_RECT_STYLE.MoveEmpty
        case GANTT_WORK_KIND.MoveEmptyRest: //空車走行（休憩有)
          return GANTT_RECT_STYLE.MoveEmptyRest
        case GANTT_WORK_KIND.MoveWait: //待ち
          return GANTT_RECT_STYLE.MoveWait
        case GANTT_WORK_KIND.DepoEnd: //到着
          return GANTT_RECT_STYLE.DepoEnd
        case GANTT_WORK_KIND.Rest: //休憩
          return GANTT_RECT_STYLE.Rest
        case GANTT_WORK_KIND.LastDepo: //宵積み
          return GANTT_RECT_STYLE.LastDepo
        // case GANTT_WORK_KIND.Trip: //トリップ
        //   return 'stroke:black; fill:#C8C8C8;'
        default:
          return 'stroke:transparent; fill:transparent;'
      }
    },

    tooltiptext() {
      switch (this.type) {
        case GANTT_WORK_KIND.DepoStart: //出発
          return (
            '【出発地】\n' +
            (this.chart.placeName || '') +
            '\n【出発時刻】\n' +
            (this.getHhmmFromDateTimeString14(this.chart.beginTime) || '') +
            '～' +
            (this.getHhmmFromDateTimeString14(this.chart.endTime) || '') +
            '\n【住所】\n' +
            (this.chart.address || '')
          )
        case GANTT_WORK_KIND.Depo: //荷積
          return (
            '【アイテム名】\n' +
            ('荷積' + (this.chart.rountNo || '')) +
            '\n【荷積場所】\n' +
            (this.chart.placeName || '') +
            '\n【荷積時刻】\n' +
            (this.getHhmmFromDateTimeString14(this.chart.beginTime) || '') +
            '～' +
            (this.getHhmmFromDateTimeString14(this.chart.endTime) || '') +
            '\n【住所】\n' +
            (this.chart.address || '')
          )
        case GANTT_WORK_KIND.Drop: //荷卸
          return (
            '【アイテム名】\n' +
            ('荷卸' + (this.chart.rountNo || '')) +
            '\n【荷卸場所】\n' +
            (this.chart.placeName || '') +
            '\n【荷卸時刻】\n' +
            (this.getHhmmFromDateTimeString14(this.chart.beginTime) || '') +
            '～' +
            (this.getHhmmFromDateTimeString14(this.chart.endTime) || '') +
            '\n【住所】\n' +
            (this.chart.address || '')
          )
        case GANTT_WORK_KIND.Move: //実車走行
        case GANTT_WORK_KIND.MoveRest: //実車走行（休憩有）
          return (
            '【走行】\n' +
            '実車' +
            '\n【走行時刻】\n' +
            (this.getHhmmFromDateTimeString14(this.chart.beginTime) || '') +
            '～' +
            (this.getHhmmFromDateTimeString14(this.chart.endTime) || '')
          )
        case GANTT_WORK_KIND.MoveEmpty: //空車走行
        case GANTT_WORK_KIND.MoveEmptyRest: //空車走行（休憩有)
          return (
            '【走行】\n' +
            '空車' +
            '\n【走行時刻】\n' +
            (this.getHhmmFromDateTimeString14(this.chart.beginTime) || '') +
            '～' +
            (this.getHhmmFromDateTimeString14(this.chart.endTime) || '')
          )
        case GANTT_WORK_KIND.MoveWait: //待ち
          return (
            '【走行】\n' +
            '待ち' +
            '\n【待ち時刻】\n' +
            (this.getHhmmFromDateTimeString14(this.chart.beginTime) || '') +
            '～' +
            (this.getHhmmFromDateTimeString14(this.chart.endTime) || '')
          )
        case GANTT_WORK_KIND.DepoEnd: //到着
          return (
            '【到着地】\n' +
            (this.chart.placeName || '') +
            '\n【到着時刻】\n' +
            (this.getHhmmFromDateTimeString14(this.chart.beginTime) || '') +
            '～' +
            (this.getHhmmFromDateTimeString14(this.chart.endTime) || '') +
            '\n【住所】\n' +
            (this.chart.address || '')
          )
        case GANTT_WORK_KIND.Rest: //休憩
          return ''
        case GANTT_WORK_KIND.LastDepo: //宵積み
          return (
            '【アイテム名】\n' +
            '宵積' +
            '\n【宵積時刻】\n' +
            (this.getHhmmFromDateTimeString14(this.chart.beginTime) || '') +
            '～' +
            (this.getHhmmFromDateTimeString14(this.chart.endTime) || '') +
            '\n【住所】\n' +
            (this.chart.address || '')
          )
        case GANTT_WORK_KIND.Trip: //トリップ
          return ''
        default:
          return ''
      }
    },
  },
  methods: {
    convertDate(value) {
      var year = value.substring(0, 4)
      var monthIndex = Number(value.substring(4, 6)) - 1
      var day = value.substring(6, 8)
      var hour = value.substring(8, 10)
      var minute = value.substring(10, 12)
      var second = value.substring(12, 14)

      value = new Date(year, monthIndex, day, hour, minute, second)
      return value
    },

    getOnlyDate(value) {
      let year = value.getFullYear()
      let monthIndex = value.getMonth()
      let date = value.getDate()
      var onlyDate = new Date(year, monthIndex, date)
      return onlyDate
    },

    getHhmmFromDateTimeString14(datetimeString) {
      if (datetimeString && datetimeString.length >= 12) {
        return datetimeString.substr(8, 2) + ':' + datetimeString.substr(10, 2)
      }
      return ''
    },
  },
}
</script>
